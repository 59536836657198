import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
})
export class LoadingIndicatorComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() type: 'ripple' | 'bar' = 'ripple';
}
