@switch (type) {
  @case ('ripple') {
    <div
      class="flex justify-center items-center 
    {{ size == 'small' ? 'h-6 w-[3.75rem]' : '' }} 
    {{ size == 'medium' ? 'h-10 w-[6.25rem]' : '' }} 
    {{ size == 'large' ? 'h-20 w-[12.5rem]' : '' }}"
    >
      <div class="rounded-full h-1/2 w-1/2 bg-blue animate-[ping_1s_ease-out_infinite_-600ms]"></div>
      <div class="rounded-full h-1/2 w-1/2 bg-blue animate-[ping_1s_ease-out_infinite_-400ms]"></div>
      <div class="rounded-full h-1/2 w-1/2 bg-blue animate-[ping_1s_ease-out_infinite_-200ms]"></div>
      <div class="rounded-full h-1/2 w-1/2 bg-blue animate-[ping_1s_ease-out_infinite]"></div>
      <div class="rounded-full h-1/2 w-1/2 bg-blue animate-[ping_1s_ease-out_infinite_-800ms]"></div>
    </div>
  }
  @case ('bar') {
    <div class="w-full bg-green-faint overflow-hidden" [class.h-0.5]="size === 'small'" [class.h-1]="size === 'medium'" [class.h-2]="size === 'large'">
      <div class="animate-progress w-full h-full bg-green-light origin-left"></div>
    </div>
  }
}
