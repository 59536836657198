import { AuthenticationService } from '@aa/authentication/authentication.service';
import { ThirdParty, ThirdPartyLoginButtonsComponent, ThirdPartyLoginEvent } from '@aa/components/third-party-login/third-party-login-buttons.component';
import { User } from '@aa/models/user';
import { UserService } from '@aa/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { environment } from '@environments/environment';
import { SvgIconComponent } from 'angular-svg-icon';
import { switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [
    SvgIconComponent,
    ThirdPartyLoginButtonsComponent,
    RouterLink,
  ],
  selector: 'dash-landing-login',
  templateUrl: './login.component.html',
  host: {
    class: 'relative grow w-full face-gradient flex flex-col',
  }
})
export class LoginComponent {
  loggingIn = false;
  errorMessage: string | null = null;
  showingPassword = false;

  thirdParties: ThirdParty[] = Object.values(environment.thirdParty);

  private router = inject(Router);
  private authService = inject(AuthenticationService);
  private userService = inject(UserService);

  onThirdPartyLoginEvent(event: ThirdPartyLoginEvent) {
    if (this.loggingIn) return;
    this.loggingIn = true;
    this.errorMessage = null;

    if (event.kind == 'failure') {
      this.errorMessage = 'Failed to log in';
      return;
    }

    if (event.kind == 'success') {
      this.userService.self()
        .subscribe({
          next: (user) => this.loginUser(user),
          error: (error) => {
            console.error(error);
            this.errorMessage = `Failed to log in with ${event.type}`;
            this.loggingIn = false;
          }
        });
    }
  }

  onClickLogin(email: string, password: string) {
    if (this.loggingIn) return;
    this.loggingIn = true;
    this.errorMessage = null;

    this.authService
      .login(email, password)
      .pipe(
        switchMap((response) => {
          if (!response?.ok) throw new Error();
          return this.userService.self();
        }),
      )
      .subscribe({
        next: (user) => this.loginUser(user),
        error: (error: HttpErrorResponse) => {
          console.error(error);
          if (error?.error && 'message' in error.error) {
            this.errorMessage = error.error.message;
          } else if ('message' in error) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = 'Failed to login';
          }

          this.loggingIn = false;
        },
      });
  }

  onClickToggleShowPassword() {
    this.showingPassword = !this.showingPassword;
  }

  private loginUser(user: User | null) {
    this.authService.setUser(user);
    this.loggingIn = false;
    if (user) {
      this.router.navigate(['/dashboards']);
    }
  }
}
