import { AuthenticationService } from '@aa/authentication/authentication.service';
import { LoadingIndicatorComponent } from '@aa/components/loading-indicator/loading-indicator.component';
import { FacebookService, InitParams, LoginResponse } from '@aa/services/facebook.service';
import { UserService } from '@aa/services/user.service';
import { DOCUMENT, NgClass, TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter, inject,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

declare const Materialize: any;
declare const google: any;
declare const AppleID: any;

// TODO: TODO: NOTE: NOTE:
// TODO: TODO: NOTE: NOTE:
// TODO: TODO: NOTE: NOTE:
//    When merging the `jk/update-components` branch and using this component over the anglersatlas/shared component, the sentry logging stuff will have to
//    happen from the caller's end. The dashboard doesn't have sentry...
// TODO: TODO: NOTE: NOTE:
// TODO: TODO: NOTE: NOTE:
// TODO: TODO: NOTE: NOTE:

export type ThirdParty = {
  id: string;
  type: string;
  logo: string;
  title: string;
};

export type ThirdPartyLoginEvent = {
  kind: 'login' | 'link' | 'success' | 'failure';
  type: string;
};

export enum ThirdPartyButtonState {
  LOGIN,
  LINK,
}

type ThirdPartyButton = ThirdParty & {
  htmlId: string;
  isLinked: boolean;
  isLoading: boolean;
  restrictions?: string;
};

let uniqueId = 0;

@Component({
  standalone: true,
  selector: 'app-third-party-login-buttons',
  templateUrl: './third-party-login-buttons.component.html',
  host: {
    class: 'tailwind',
  },
  imports: [
    LoadingIndicatorComponent,
    NgClass,
    TitleCasePipe,
  ],
  providers: [
    { provide: FacebookService },
  ],
})
export class ThirdPartyLoginButtonsComponent implements OnChanges {
  static appleInitialized = false;
  static facebookInitialized = false;
  static facebookFailed = false;
  static googleInitialized = false;

  @Input({ required: true }) thirdParties: ThirdParty[];

  @Input() state: ThirdPartyButtonState = ThirdPartyButtonState.LOGIN;
  @Input() stateData: string[] = [];
  @Input() compact: boolean = false;

  @Output() loginEvent = new EventEmitter<ThirdPartyLoginEvent>();
  @Output() linkEvent = new EventEmitter<ThirdPartyLoginEvent>();

  public ThirdPartyButtonState = ThirdPartyButtonState;

  public errorMessage: string;

  public buttons: ThirdPartyButton[] = [];

  private fb = inject(FacebookService);
  private auth = inject(AuthenticationService);
  private userService = inject(UserService);
  private zone = inject(NgZone);
  private document = inject<Document>(DOCUMENT);

  constructor() {
    uniqueId += 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('stateData' in changes) {
      this.setButtonStates();
    }
    if ('thirdParties' in changes) {
      this.bindButtons();
    }
  }

  private bindButtons() {
    const buttons: ThirdPartyButton[] = [];
    for (const it of this.thirdParties) {
      const type = it.type;

      const htmlId = `third-party-${it.type}${uniqueId}`;
      const isLinked = this.stateData.includes(type);
      const button: ThirdPartyButton = {
        ...it,
        htmlId,
        isLinked,
        isLoading: true,
      };

      buttons.push(button);
    }

    setTimeout(() => {
      this.buttons = buttons;
      this.initFacebook();
      this.initApple();
      this.initGoogle();
    });
  }

  unlink(button: ThirdPartyButton) {
    if (this.state === ThirdPartyButtonState.LINK && button.isLinked) {
      this.performUnlink(button.type);
    }
  }

  private setButtonStates() {
    if (!this.stateData) return;

    for (let i = 0; i < this.buttons.length; i++) {
      const type = this.buttons[i].type;
      this.buttons[i].isLinked = this.stateData.includes(type);
    }
  }

  private initApple() {
    const button = this.buttons.find((it) => it.type == 'apple_web');
    if (!button) return;

    if (!ThirdPartyLoginButtonsComponent.appleInitialized) {
      ThirdPartyLoginButtonsComponent.appleInitialized = true;

      AppleID.auth.init({
        clientId: button.id,
        scope: 'name email',
        redirectURI: 'https://www.anglersatlas.com/login',
        usePopup: true,
      });
    }

    button.isLoading = false;
  }

  private initFacebook() {
    if (!this.fb) return;
    if (ThirdPartyLoginButtonsComponent.facebookFailed) return;

    const button = this.buttons.find((it) => it.type == 'facebook');
    if (!button) return;

    if (ThirdPartyLoginButtonsComponent.facebookInitialized) {
      button.isLoading = false;
    } else {
      const initParams: InitParams = {
        appId: button.id,
        xfbml: true,
        version: 'v2.12',
      };

      this.fb
        .init(initParams)
        .then(() => {
          ThirdPartyLoginButtonsComponent.facebookInitialized = true;
          button.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loginEvent.emit({ kind: 'failure', type: 'facebook' });
          ThirdPartyLoginButtonsComponent.facebookFailed = true;
        });
    }
  }

  private initGoogle() {
    if (ThirdPartyLoginButtonsComponent.googleInitialized) return;

    // NOTE(cg): Sometimes loading Google is slow, and we get here before it's ready.
    //  So, if it's not defined, then add a listener and wait until it is.
    const window: Window = this.document.defaultView;
    if (!('google' in window)) {
      window["onGoogleLibraryLoad"] = () => this.initGoogle();
      return;
    }

    const thirdParty = this.thirdParties.find((it) => it.type == 'google')
    if (!thirdParty) return;

    google.accounts.id.initialize({
      client_id: thirdParty.id,
      callback: (response: any) => {
        this.zone.run(() => this.thirdPartyLoginSuccessful('google', response.credential));
      }
    });

    const button = this.getButtonByType("google");
    if (button) {
      button.isLoading = false;

      setTimeout(() => {
        const parent = document.getElementById(button.htmlId);
        google.accounts.id.renderButton(parent, {
          type: "icon",
          shape: "circle",
          theme: "outline",
          text: "continue_with",
          size: "large",
        });
      });
    }
  }


  private getButtonByType(type: string): ThirdPartyButton | null {
    let result: ThirdPartyButton | null = null;
    for (const it of this.buttons) {
      result = (it.type == type) ? it : null;
      if (result) break;
    }
    return result;
  }

  private addFacebookRestriction() {
    const button = this.getButtonByType('facebook');
    if (button) {
      button.restrictions = `Unable to load Facebook Module.
Please check that cookies are enable, tracking protection is off,
or extensions are not restricting Facebook.`;
    }
  }

  login(type: string) {
    this.loginEvent.emit({ kind: 'login', type });

    switch (type) {
      case 'google':
        break;
      case 'facebook':
      case 'facebook_mycatch':
        if (ThirdPartyLoginButtonsComponent.facebookFailed) {
          this.addFacebookRestriction();
        } else {
          this.handleFacebookLogin(type);
        }
        break;
      case 'apple':
      case 'apple_web':
        this.handleAppleLogin();
    }
  }

  private handleFacebookLogin(type: 'facebook_mycatch' | 'facebook') {
    this.fb
      .login({ scope: 'email,public_profile' })
      .then((response: LoginResponse) => {
        const token = response.authResponse.accessToken;
        this.thirdPartyLoginSuccessful(type, token);
      })
      .catch((error) => {
        // TODO: Should this show an error message to the user??
        console.error(error);
        this.loginEvent.emit({ kind: 'failure', type });
      });
  }

  private async handleAppleLogin() {
    try {
      const data = await AppleID.auth.signIn();
      this.thirdPartyLoginSuccessful('apple_web', data.authorization.code);
    } catch (error) {
      // TODO: Should this show an error message to the user??
      console.error(error);
      this.loginEvent.emit({ kind: 'failure', type: 'apple_web' });
    }
  }

  private thirdPartyLoginSuccessful(type: string, token: string) {
    switch (this.state) {
      case ThirdPartyButtonState.LOGIN:
        this.performLogin(type, token);
        break;
      case ThirdPartyButtonState.LINK:
        this.linkEvent.emit({ kind: 'link', type });
        this.performLink(type, token);
        break;
    }
  }

  private performLogin(type: string, token: string) {
    const button = this.getButtonByType(type);
    if (button == null) return;
    button.isLoading = true;

    this.errorMessage = null;
    this.auth.thirdPartyLogin(type, token)
      .subscribe({
        next: () => {
          button.isLoading = false;
          this.loginEvent.emit({ kind: 'success', type });
        },
        error: (error) => {
          this.loginEvent.emit({ kind: 'failure', type });
          console.error(error);

          if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
          } else if (error.message) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = 'Failed to log in';
          }
          button.isLoading = false;
        },
      });
  }

  private performLink(type: string, token: string) {
    const button = this.getButtonByType(type);
    if (button == null) return;
    button.isLoading = true;

    this.userService.updateSelf({
      third_party_link: {
        type,
        token,
      },
    }).subscribe({
      next: () => {
        this.linkEvent.emit({ kind: 'success', type });

        button.isLoading = false;
        button.isLinked = true;

        Materialize.toast('Account successfully linked!', 4000);
      },
      error: (error) => {
        this.linkEvent.emit({ kind: 'failure', type });

        if (error.error && error.error.message) {
          this.errorMessage = error.error.message;
        } else if (error.message) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = 'Failed to log in';
        }
        button.isLoading = false;
      },
    });
  }

  private performUnlink(type: string) {
    const button = this.getButtonByType(type);
    if (button == null) return;
    button.isLoading = true;

    this.userService
      .updateSelf({ third_party_unlink: type })
      .subscribe({
        next: () => {
          this.linkEvent.emit({ kind: 'success', type });

          button.isLoading = false;
          button.isLinked = false;

          Materialize.toast('Account successfully unlinked!', 4000);
        },
        error: (error) => {
          this.linkEvent.emit({ kind: 'failure', type });

          if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
          } else if (error.message) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = 'Failed to log in';
          }

          button.isLoading = false;
        },
      });
  }
}
